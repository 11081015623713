import { createRouter, createWebHistory } from "vue-router";

const routes = [
  { path: '/', name: 'home', component: () => import('../views/home.vue') },
  
  { path: '/appointments/new', name: 'new_appointment_old', component: () => import('../views/appointments/new_old.vue') },
  
  { path: '/terminbuchung', name: 'new_appointment', component: () => import('../views/appointments/new.vue') },

  { path: '/wartezeit', name: 'appointment_waiting_time', component: () => import('../views/appointments/waiting_time.vue') },

  { path: '/appointments/:uuid', name: 'appointment', component: () => import('../views/appointments/show.vue') },

  { path: '/waiting_lines/:uuid', name: 'waiting_line', component: () => import('../views/waiting_lines/show.vue') },


  { path: '/auth/sign_in', name: 'auth_sign_in', component: () => import('../views/auth/sign_in.vue') },
  { path: '/login', name: 'login', component: () => import('../views/auth/sign_in.vue') },


  { path: '/admin/dashboard', name: 'admin_dashboard', component: () => import('../views/admin/dashboard/index.vue') },


  { path: '/admin/appointments', name: 'admin_appointments', component: () => import('../views/admin/appointments/index.vue') },
  { path: '/admin/appointments/:uuid', name: 'admin_appointment', component: () => import('../views/admin/appointments/show.vue') },

  { path: '/admin/waiting_lines', name: 'admin_waiting_lines', component: () => import('../views/admin/waiting_lines/index.vue') },
  { path: '/admin/waiting_lines/:uuid', name: 'admin_waiting_line', component: () => import('../views/admin/waiting_lines/show.vue') },

  { path: '/admin/receipts', name: 'admin_receipts', component: () => import('../views/admin/receipts/index.vue') },
  { path: '/admin/receipts/:uuid', name: 'admin_receipt', component: () => import('../views/admin/receipts/show.vue') },


  { path: '/admin/statistics', name: 'admin_statistics', component: () => import('../views/admin/statistics/index.vue') },


  { path: '/admin/users', name: 'admin_users', component: () => import('../views/admin/users/index.vue') },
  { path: '/admin/users/:uuid', name: 'admin_user', component: () => import('../views/admin/users/show.vue') },
  { path: '/admin/users/:uuid/dashboard', name: 'admin_user_dashboard', component: () => import('../views/admin/users/dashboard.vue') },
  { path: '/admin/users/self', name: 'admin_users_self', component: () => import('../views/admin/users/self.vue') },

  { path: '/admin/counters', name: 'admin_counters', component: () => import('../views/admin/counters/index.vue') },

  { path: '/admin/locations', name: 'admin_locations', component: () => import('../views/admin/locations/index.vue') },
  { path: '/admin/locations/:uuid', name: 'admin_location', component: () => import('../views/admin/locations/show.vue') },

  { path: '/admin/categories', name: 'admin_categories', component: () => import('../views/admin/categories/index.vue') },
  { path: '/admin/services', name: 'admin_services', component: () => import('../views/admin/services/index.vue') },

  { path: '/admin/fees', name: 'admin_fees', component: () => import('../views/admin/fees/index.vue') },

  { path: '/admin/settings', name: 'admin_settings', component: () => import('../views/admin/settings/index.vue') },
  { path: '/admin/closing_days', name: 'admin_closing_days', component: () => import('../views/admin/closing_days/index.vue') },

  { path: '/admin/languages', name: 'admin_languages', component: () => import('../views/admin/languages/index.vue') },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
