<template>
  <div>
    <notifications position="top center" />

    <div v-if="get_route == 'waiting_line'">
      <router-view />
    </div>
    <div v-else-if="get_route == 'appointment'">
      <div class="app-wrap">
        <header class="app-header">
          <p class="logo"><img alt="Logo" src="@/assets/logo.svg" /></p>
        </header>
        <div class="app-body">
          <router-view />
        </div>
      </div>
      <footer class="app-footer cf">
        <ul class="nav-footer">
          <li><a href="https://www.schorndorf.de/de/kontakt" target="_blank">Kontakt</a></li>
          <li><a href="https://www.schorndorf.de/de/datenschutz" target="_blank">Datenschutz</a></li>
          <li><a href="https://www.schorndorf.de/de/impressum" target="_blank">Impressum</a></li>
        </ul>
        <div class="copyright">
          <p>Powered by <a href="https://www.nodion.com" target="_blank">Nodion Service Center</a> {{ meta.version }}</p>
        </div>
      </footer>
    </div>
    <div v-else-if="get_route == 'new_appointment'">
      <div class="app-wrap">
        <header class="app-header">
          <p class="logo"><img alt="Logo" src="@/assets/logo.svg" /></p>
        </header>
        <div class="app-body">
          <router-view />
        </div>
      </div>
      <footer class="app-footer cf">
        <ul class="nav-footer">
          <li><a href="https://www.schorndorf.de/de/kontakt" target="_blank">Kontakt</a></li>
          <li><a href="https://www.schorndorf.de/de/datenschutz" target="_blank">Datenschutz</a></li>
          <li><a href="https://www.schorndorf.de/de/impressum" target="_blank">Impressum</a></li>
        </ul>
        <div class="copyright">
          <p>Powered by <a href="https://www.nodion.com" target="_blank">Nodion Service Center</a> {{ meta.version }}</p>
        </div>
      </footer>
    </div>
    <div v-else-if="get_route == 'appointment_waiting_time'">
      <div class="app-wrap">
        <header class="app-header">
          <p class="logo"><img alt="Logo" src="@/assets/logo.svg" /></p>
        </header>
        <div class="app-body">
          <router-view />
        </div>
      </div>
      <footer class="app-footer cf">
        <ul class="nav-footer">
          <li><a href="https://www.schorndorf.de/de/kontakt" target="_blank">Kontakt</a></li>
          <li><a href="https://www.schorndorf.de/de/datenschutz" target="_blank">Datenschutz</a></li>
          <li><a href="https://www.schorndorf.de/de/impressum" target="_blank">Impressum</a></li>
        </ul>
        <div class="copyright">
          <p>Powered by <a href="https://www.nodion.com" target="_blank">Nodion Service Center</a> {{ meta.version }}</p>
        </div>
      </footer>
    </div>
    <div v-else>
      <div v-if="is_logged_in">
        <div class="app-be">
          <div class="app-aside">
            <p class="logo"><img alt="Logo" src="@/assets/logo.svg" /></p>
            <ul class="nav nav-main">
              <h3>Arbeitsplatz</h3>
              <li>
                <router-link to="/admin/dashboard">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                  </svg>
                  <span>Dashboard</span>
                </router-link>
              </li>
              <li>
                <router-link to="/admin/appointments">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                  <span>Termine</span>
                </router-link>
              </li>
              <li>
                <router-link to="/admin/waiting_lines">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span>Warteschlangen</span>
                </router-link>
              </li>
              <li>
                <router-link to="/admin/receipts">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                  <span>Quittungen</span>
                </router-link>
              </li>
              <h3 v-if="user && user.role == 'administrator'">Verwaltung</h3>
              <li v-if="user && user.role == 'administrator'">
                <router-link to="/admin/statistics">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                  <span>Statistiken</span>
                </router-link>
              </li>
              <li v-if="user && user.role == 'administrator'">
                <router-link to="/admin/users">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                  <span>Mitarbeiter</span>
                </router-link>
              </li>
              <li v-if="user && user.role == 'administrator'">
                <router-link to="/admin/counters">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                  </svg>
                  <span>Schalter</span>
                </router-link>
              </li>
              <li v-if="user && user.role == 'administrator'">
                <router-link to="/admin/locations">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                  </svg>
                  <span>Standorte</span>
                </router-link>
              </li>
              <li v-if="user && user.role == 'administrator'">
                <router-link to="/admin/categories">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                  <span>Kategorien</span>
                </router-link>
              </li>
              <li v-if="user && user.role == 'administrator'">
                <router-link to="/admin/services">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                  </svg>
                  <span>Leistungen</span>
                </router-link>
              </li>
              <li v-if="user && user.role == 'administrator'">
                <router-link to="/admin/fees">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                  <span>Gebühren</span>
                </router-link>
              </li>
              <!--<li v-if="user && user.role == 'administrator'">
                <router-link to="/admin/settings">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <span>Einstellungen</span>
                </router-link>
              </li>-->
              <li v-if="user && user.role == 'administrator'">
                <router-link to="/admin/closing_days">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636" />
                  </svg>
                  <span>Schließtage</span>
                </router-link>
              </li>
              <li v-if="user && user.role == 'administrator'">
                <router-link to="/admin/languages">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 21l5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 016-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 01-3.827-5.802" />
                  </svg>
                  <span>Sprachen</span>
                </router-link>
              </li>
            </ul>
            <div class="user-details">
              <p v-if="user">Eingeloggt als <router-link to="/admin/users/self">{{ user.first_name }} {{ user.last_name }}</router-link>.</p>
              <div style="margin-top:10px">
                <!--<p style="margin:6px 0">
                  <svg xmlns="http://www.w3.org/2000/svg" style="width:18px;height:18px;display:inline-block;vertical-align:middle;" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <span style="margin-left:5px;display:inline-block;vertical-align:middle;">Einstellungen</span>
                </p>-->
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" style="width:18px;height:18px;display:inline-block;vertical-align:middle;" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                  </svg>
                  <a @click="logout" style="margin-left:5px;display:inline-block;vertical-align:middle;color:#444">Jetzt ausloggen ›</a>
                </p>
              </div>
            </div>
          </div>
          <div class="app-body">
            <div class="body-inner">
              <router-view />
            </div>
          </div>
        </div>
        <div v-if="update_available" @click="reload_now" class="version-info version-update">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
          <span>Es ist ein Update verfügbar!</span>
          <span class="btn">Bitte hier klicken um neu zu laden ›</span>
        </div>
        <div v-else class="version-info">
          {{ meta.version }}
        </div>
      </div>
      <div v-else>
        <div class="app-wrap">
          <header class="app-header">
            <p class="logo"><img alt="Logo" src="@/assets/logo.svg" /></p>
          </header>
          <div class="app-body">
            <router-view />
          </div>
        </div>
        <footer class="app-footer cf">
          <ul class="nav-footer">
            <li><a href="https://www.schorndorf.de/de/kontakt" target="_blank">Kontakt</a></li>
            <li><a href="https://www.schorndorf.de/de/datenschutz" target="_blank">Datenschutz</a></li>
            <li><a href="https://www.schorndorf.de/de/impressum" target="_blank">Impressum</a></li>
          </ul>
          <div class="copyright">
            <p>Powered by <a href="https://www.nodion.com" target="_blank">Nodion Service Center</a> {{ meta.version }}</p>
          </div>
        </footer>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  computed : {
    is_logged_in : function(){ return this.$store.getters.is_logged_in },
    get_route() {
      return this.$route.name;
    }
  },
  data () {
    return {
      meta: {},
      user: {},
      current_route: null,
      update: false,
      api_build: null,
      update_available: false
    }
  },
  methods: {
    async get_version() {
      await this.$http.get(process.env.VUE_APP_BASE_URL, {})
      .then(response => {
        this.meta = response.data;
        this.check_build();
      })
    },
    get_user() {
      if (this.get_route && this.get_route.includes("admin")) {
        this.get_user_request();
      } else if (this.$store.getters.is_logged_in) {
        this.get_user_request();
      }
    },
    get_user_request() {
      this.$http.get(process.env.VUE_APP_BASE_API+'/user', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.user = response.data.user;
      })
      .catch(error => {
        if (error.response.status === 401) {
          this.$store.commit('logout', '');
          this.$router.push('/');
        } else if (error.response.status === 400) {
          if (error.response.data.errors == "user not found.") {
            this.$store.commit('logout', '');
            if (this.get_route.includes("admin")) {
              this.$notify({
                title: "Bitte neu einloggen.",
                type: "success"
              });
              this.$router.push('/login');
            } else {
              this.$router.push('/');
            }
          }
        }
      })
    },
    livesign() {
      if (this.is_logged_in) {
        this.$http.post(process.env.VUE_APP_BASE_API+'/user/livesign', {
          fe_build: 1
        }, { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.update = response.data.update;
        })
        /*.catch(error => {
          console.log("ERROR", error)
        })*/
      }
    },
    async logout() {
      await this.$http.delete(process.env.VUE_APP_BASE_API+'/admin/logout', { headers: { Authorization: this.$store.getters.get_token } })
      this.$store.commit('logout', '');
      this.$router.push('/');
    },
    check_build() {
      if (this.is_logged_in) {
        if (parseInt(this.meta.build) > parseInt(process.env.VUE_APP_BUILD)) {
          this.update_available = true;
        }
      }
    },
    reload_now() {
      location.reload();
    }
  },
  mounted () {
    //
    this.get_version();
    this.get_user();
    /*this.livesign();
    var _this = this;
    setInterval(function() {
      _this.livesign();
    }, 5000);*/
  },
  watch:{
    $route() {
      this.get_user();
    }
  }
}

</script>
<style lang="scss">
@import 'assets/css/normalize.css';
@import 'assets/css/skadi.scss';
@import 'assets/css/style.scss';
@import 'assets/css/resp.scss';
</style>
